import '../scss/site.scss'
import adjust from './ratio'
import {} from './menu'
import {} from './pagination'
import {} from './audio-player'
import {} from './contact'
import {} from './comments'
import {} from './oembed'
import {} from './recaptcha'
import imagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout'

document.querySelectorAll('.ratio-unknown').forEach(adjust)
document.querySelectorAll('.grid-masonry').forEach(
    (grid) => {
        const imgs = imagesLoaded(grid)
        let imgsLoaded = false
        let videos = grid.querySelectorAll('video')
        let videosRemaining = videos.length
        let masonry

        const go = () => {
            masonry = new Masonry(
                grid,
                {
                    percentPosition: true,
                    initLayout: false
                }
            )

            masonry.once('layoutComplete',
                (elements) => {
                    elements.forEach(
                        (element) => {
                            element.element.querySelectorAll('.ratio-unknown').forEach(
                                (ratio) => {
                                    window.requestAnimationFrame(
                                        () => adjust(ratio)
                                    )
                                }
                            )
                        }
                    )
                }
            )

            masonry.layout()
        }

        imgs.on(
            'done',
            () => {
                imgsLoaded = true

                if (!videosRemaining) {
                    go()
                }
            }
        )

        videos.forEach(
            (video) => {
                video.addEventListener('durationchange',
                    (e) => {
                        if (video.duration) {
                            videosRemaining --
                        }

                        if (!videosRemaining && imgsLoaded) {
                            go()
                        }
                    }
                )
            }
        )

        grid.addEventListener('masonry.append',
            (e) => {
                const ajaxImgs = imagesLoaded(e.detail.elements)

                ajaxImgs.on(
                    'done',
                    () => {
                        masonry.appended(e.detail.elements, true)
                        e.detail.done()
                    }
                )
            }
        )
    }
)

document.addEventListener(
    'ajax',
    (e) => {
        e.target.querySelectorAll('.ratio-unknown').forEach(adjust)
    },
    true
)

import {} from 'bootstrap'
