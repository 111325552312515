document.addEventListener('beforesubmit',
    (e) => {
        const captchaField = e.target.querySelector('input[data-recaptcha-key]')

        if (!e.target.closest('.xhr-form-container')) {
            return
        }

        if (!captchaField) {
            return
        }

        const captchaKey = captchaField.getAttribute('data-recaptcha-key')
        if (e.detail && e.detail.pause) {
            e.detail.pause()
        }

        e.preventDefault()
        e.stopPropagation()

        grecaptcha.ready(
            () => {
                grecaptcha.execute(
                    captchaKey,
                    {
                        action: 'submit'
                    }
                ).then(
                    (token) => {
                        captchaField.value = token
                        if (e.detail && e.detail.resume) {
                            e.detail.resume()
                        }
                    }
                ).catch(
                    () => {
                        if (e.detail && e.detail.resume) {
                            e.detail.resume()
                        }
                    }
                )
            }
        )
    },
    true
)

document.addEventListener('submit',
    (e) => {
        const captchaField = e.target.querySelector('input[data-recaptcha-key]')

        if (e.target.closest('.xhr-form-container')) {
            return
        }

        if (!captchaField) {
            return
        }

        const captchaKey = captchaField.getAttribute('data-recaptcha-key')
        e.preventDefault()
        e.stopPropagation()

        grecaptcha.ready(
            () => {
                grecaptcha.execute(
                    captchaKey,
                    {
                        action: 'submit'
                    }
                ).then(
                    (token) => {
                        captchaField.value = token
                        e.target.submit()
                    }
                ).catch(
                    () => {
                        e.target.submit()
                    }
                )
            }
        )
    }
)
