(
    () => {
        const submitForm = (form) => {
            const content = form.closest('.modal-content')

            form.querySelectorAll('button').forEach(
                (btn) => {
                    btn.classList.add('disabled')
                    btn.disabled = true
                }
            )

            let cancelled = false
            let paused = false

            const c = {
                cancel: () => {
                    cancelled = true
                    form.querySelectorAll('button').forEach(
                        (btn) => {
                            btn.classList.remove('disabled')
                            btn.disabled = false
                        }
                    )
                },
                pause: () => {
                    paused = true
                },
                resume: () => {
                    if (!paused) {
                        throw new Error('Form not paused')
                    }

                    paused = false
                    go()
                }
            }

            const go = () => {
                const args = {
                    method: form.method,
                    mode: 'cors',
                    cache: 'no-cache',
                    redirect: 'follow',
                    redirectPolicy: 'no-referrer',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }

                let url = form.action

                if (form.method.toUpperCase() !== 'GET') {
                    args.body = new FormData(form)
                } else {
                    const entries = Array.from(new FormData(form).entries())
                    const pairs = []

                    entries.forEach(
                        (entry) => {
                            const key = encodeURIComponent(entry[0])
                            const value = encodeURIComponent(entry[1])

                            pairs.push(`${key}=${value}`)
                        }
                    )

                    if (pairs.length) {
                        url += '?' + pairs.join('&')
                    }
                }

                fetch(url, args).then(
                    (response) => {
                        response.text().then(
                            (html) => {
                                if (content) {
                                    content.style.height = 'auto'
                                    content.innerHTML = html

                                    const field = form.querySelector('.form-control:invalid')

                                    if (field) {
                                        field.focus()
                                    }
                                }
                            }
                        )
                    }
                )
            }

            form.dispatchEvent(
                new CustomEvent(
                    'beforesubmit',
                    {
                        detail: c
                    }
                )
            )

            if (cancelled || paused) {
                return
            }

            go()
        }

        document.addEventListener('show.bs.modal',
            (e) => {
                const btn = e.relatedTarget
                const modal = e.target
                const content = modal.querySelector('.modal-content')

                if (btn && btn.href) {
                    const url = new URL(btn.href)

                    fetch(
                        btn.href,
                        {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest'
                            }
                        }
                    ).then(
                        (response) => {
                            response.text().then(
                                (html) => {
                                    if (content) {
                                        content.style.height = 'auto'
                                        content.innerHTML = html
                                    }
                                }
                            )
                        }
                    )
                }
            },
            true
        )

        document.addEventListener('shown.bs.modal',
            (e) => {
                const content = e.target.querySelector('.modal-content')
                const field = content.querySelector('.form-control')

                if (field) {
                    field.focus()
                }
            },
            true
        )

        document.addEventListener('click',
            (e) => {
                const btn = e.target.getAttribute('data-action') === 'back' ? e.target : e.target.closest('[data-action="back"]')

                if (!btn) {
                    return
                }

                const modal = e.target.closest('.modal')

                if (!modal) {
                    return
                }

                e.preventDefault()
                e.stopPropagation()

                const field = modal.querySelector('input[name="back"]')

                if (field) {
                    field.value = 'on'
                    submitForm(field.closest('form'))
                }
            }
        )

        document.addEventListener('submit',
            (e) => {
                const modal = e.target.closest('.modal')

                if (!modal) {
                    return
                }

                e.preventDefault()
                e.stopPropagation()
                submitForm(e.target)
            }
        )

    }
)()