import WaveSurfer from 'wavesurfer.js'

(
    () => {
        const players = []

        document.querySelectorAll('.audio-player').forEach(
            (container) => {
                const audio = container.querySelector('audio')
                const playBtn = container.querySelector('.play-pause-btn')
                const wavesurfer = WaveSurfer.create(
                    {
                        container: container.querySelector('.player-waveform'),
                        media: audio,
                        waveColor: '#DCD6DC',
                        progressColor: '#564256'
                    }
                )

                let playing = false

                wavesurfer.on('decode',
                    () => {
                        playBtn.disabled = false
                    }
                )

                wavesurfer.on('play',
                    () => {
                        playBtn.innerHTML = '<i class="bi bi-pause"></i>'
                        playing = true

                        players.filter(
                            (player) => {
                                return player !== wavesurfer
                            }
                        ).forEach(
                            (player) => {
                                player.pause()
                            }
                        )
                    }
                )

                wavesurfer.on('pause',
                    () => {
                        playBtn.innerHTML = '<i class="bi bi-play-fill"></i>'
                        playing = false
                    }
                )

                playBtn.addEventListener('click',
                    (e) => {
                        e.preventDefault()

                        if (playing) {
                            wavesurfer.pause()
                        } else {
                            wavesurfer.play()
                        }
                    }
                )

                players.push(wavesurfer)
            }
        )
    }
)()