document.querySelectorAll('[data-oembed-url]').forEach(
    (div) => {
        const url = div.getAttribute('data-oembed-url')
        const load = () => {
            div.innerHTML = '<div class="margin-auto"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>'
            div.classList.remove('ratio')
            div.classList.remove('ratio-unknown')
            div.classList.add('background-light')
            div.classList.add('rounded-3')
            div.classList.add('show-flex')
            div.classList.add('flex-row')
            div.classList.add('align-items-center')
            div.style.height = `${parseInt(div.clientWidth / 16 * 9)}px`

            fetch(
                url,
                {
                    mode: 'cors'
                }
            ).then(
                (response) => {
                    response.json().then(
                        (json) => {
                            if (json.error) {
                                div.classList.remove('ratio')
                                div.classList.remove('ratio-unknown')
                                div.innerHTML = '<div class="margin-auto"><div class="text-center"><i class="bi bi-exclamation-triangle-fill"></i></div><div><button data-action="retry" class="btn">Load video</button></div></div>'
                                return
                            }

                            div.classList.remove('background-light')
                            div.classList.remove('rounded-3')
                            div.classList.remove('show-flex')
                            div.classList.remove('flex-row')
                            div.classList.remove('align-items-center')
                            div.classList.add('ratio')
                            div.classList.add('ratio-unknown')
                            div.style.height = 'auto'
                            div.innerHTML = json.html

                            const iframe = div.querySelector('iframe')

                            divWidth = div.clientWidth

                            if (!iframe) {
                                div.classList.remove('ratio-unknown')
                                div.classList.remove('ratio')
                                return
                            }

                            let iframeWidth = iframe.getAttribute('width')
                            let iframeHeight = iframe.getAttribute('height')

                            if (iframeWidth !== null) {
                                iframeWidth = iframeWidth.toString()
                            }

                            if (iframeHeight !== null) {
                                iframeHeight = iframeHeight.toString()
                            }

                            if (iframeWidth.substr(iframeWidth.length - 2) === 'px') {
                                iframeWidth = iframeWidth.substr(0, iframeWidth.length - 2)
                            }

                            if (iframeWidth.substr(iframeWidth.length - 1) == '%') {
                                const percent = parseInt(iframeWidth.substr(0, iframeWidth.length - 1))
                                
                                if (!iframeHeight) {
                                    iframeWidth = divWidth / 100 * percent
                                    iframeHeight = parseInt(iframeWidth / 16 * 9)
                                } else {
                                    div.classList.remove('ratio-unknown')
                                    div.classList.remove('ratio')
                                    return
                                }
                            } else if (iframeHeight.substr(iframeHeight.length - 2) == 'px') {
                                iframeHeight = iframeHeight.substr(0, iframeHeight.length - 2)
                            }

                            iframeWidth = parseInt(iframeWidth)
                            iframeHeight = parseInt(iframeHeight)

                            if (isNaN(iframeWidth) || isNaN(iframeHeight)) {
                                div.classList.remove('ratio-unknown')
                                div.classList.remove('ratio')
                                return
                            }

                            iframeHeight = parseInt(divWidth / iframeWidth * iframeHeight)
                            iframe.setAttribute('width', divWidth)
                            iframe.setAttribute('height', iframeHeight)
                            
                            div.style.height = iframeHeight + 'px'
                            div.classList.remove('ratio-unknown')
                        }
                    )
                }
            ).catch(
                (err) => {
                    div.classList.remove('ratio')
                    div.classList.remove('ratio-unknown')
                }
            )
        }

        const scroll = () => {
            const rect = div.getBoundingClientRect()
            const inViewport = (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
            )

            if (inViewport) {
                window.removeEventListener('scroll', scroll)
                load()
            }
        }

        div.addEventListener('click',
            (e) => {
                const btn = e.target.tagName === 'BUTTON' && e.target.getAttribute('data-action') === 'retry' ? e.target : e.target.closest('button[data-action="retry"]')

                if (!btn) {
                    return
                }

                e.preventDefault()
                load()
            }
        )

        let divWidth = div.clientWidth

        div.style.height = `${parseInt(divWidth / 16 * 9)}px`
        window.addEventListener('scroll', scroll)
        window.requestAnimationFrame(scroll)
    }
)
