import md5 from 'md5'

(
    () => {
        let updating = false
        let previousEmailAddress = ''

        document.addEventListener('input',
            (e) => {
                const input = e.target.matches('form.comment-form input[name="email"]') ? e.target : null

                if (!input) {
                    return
                }

                let imgBound = false
                let pendingAddress = null

                const loadImage = (e) => {
                    updating = false

                    if (pendingAddress) {
                        console.debug('OK, and breathe')
                        updateEmail(pendingAddress, true)
                    } else {
                        console.debug('Loaded avatar', e.target.src)
                    }
                }

                const updateEmail = (newAddress, forceUpdate) => {
                    if (updating) {
                        if (!forceUpdate) {
                            pendingAddress = pendingAddress
                            console.warn('Still loading')
                            return
                        }

                        newAddress = pendingAddress
                        pendingAddress = null
                    }

                    if (previousEmailAddress === newAddress) {
                        return
                    }

                    previousEmailAddress = newAddress

                    const img = input.form.querySelector('img.comment-avatar')

                    if (!img) {
                        return
                    }

                    if (!imgBound) {
                        img.addEventListener('load', loadImage)
                        img.addEventListener('error', loadImage)
                        imgBound = true
                    }

                    updating = true
                    img.src = `https://secure.gravatar.com/avatar/${md5(newAddress)}?s=128&d=mp&r=g`
                    console.debug(newAddress)
                }

                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                let emailAddress = ''

                if (emailPattern.test(input.value)) {
                    emailAddress = input.value
                } else {
                    emailAddress = 'none@example.com'
                }

                if (emailAddress !== previousEmailAddress) {
                    updateEmail(emailAddress)
                }
            }
        )
    }
)()

document.addEventListener('submit',
    (e) => {
        const form = e.target
        const container = form.closest('.comment-form-container')

        if (!form.classList.contains('comment-form')) {
            return
        }

        if (!container) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        e.target.querySelectorAll('button').forEach(
            (btn) => {
                btn.classList.add('disabled')
                btn.disabled = true
            }
        )

        let cancelled = false
        let paused = false

        const c = {
            cancel: () => {
                cancelled = true
            },
            pause: () => {
                paused = true
            },
            resume: () => {
                if (!paused) {
                    throw new Error('Form not paused')
                }

                paused = false
                go()
            }
        }

        const go = () => {
            fetch(
                e.target.action,
                {
                    method: e.target.method,
                    mode: 'cors',
                    cache: 'no-cache',
                    redirect: 'follow',
                    redirectPolicy: 'no-referrer',
                    body: new FormData(e.target)
                }
            ).then(
                (response) => {
                    response.text().then(
                        (html) => {
                            if (container) {
                                container.innerHTML = html

                                const field = container.querySelector('.form-control:invalid')

                                if (field) {
                                    field.focus()
                                }
                            }
                        }
                    )
                }
            )
        }

        form.dispatchEvent(
            new CustomEvent(
                'beforesubmit',
                {
                    detail: c  
                }
            )
        )

        if (cancelled || paused) {
            return
        }

        go()
    }
)
